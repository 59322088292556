import { validateValue } from '../Utils/checkout-input-validator'
import { getSSRGlobalResourceData } from '../Services/checkout-data-loader'
import { Address, Contact, CheckoutCart, ShippingOption } from '../types'
import { hasPhysicalProductsWithParams } from '../StripeCheckout/utils'

const CheckoutStates = {
  OTO: 'oto',
  GUEST: 'guest',
  SAVED: 'saved',
  UPGRADE_DOWNGRADE: 'upgradeDowngrade',
  REACTIVATE: 'reactivate',
}

const StoreStates = {
  START: 'start',
  INITIALIZING: 'initializing',
  INITIALIZED: 'initialized',
  FILLING_FORM: 'filling-form',
  SUBMITTING: 'submitting',
  SUBMITTED: 'submitted',
}

const SummaryStates = {
  WAITING: 'waiting',
  CALCULATING: 'calculating',
  ERROR: 'error',
  OK: 'ok',
}

const CouponStates = {
  READY: 'ready',
  APPLYING: 'applying',
  APPLIED: 'applied',
  ERROR: 'error',
}

const PaymentStates = {
  START: 'start',
  INITIALIZING: 'initializing',
  LOADING: 'loading',
  INITIALIZED: 'initialized',
}

const SubmittingStates = {
  IDLE: 'idle',
  START: 'start',
  WAITING_ON_QUEUE: 'waiting-on-queue',
  ERROR: 'error',
  DONE: 'done',
}

const PaypalStates = {
  IDLE: 'idle',
  INITIALIZED: 'initialized',
  ERROR: 'error',
  ADDING_PAYMENT_METHOD: 'adding-payment-method',
  PAYMENT_METHOD_APPROVED: 'payment-method-approved',
}

const ErrorTypes = {
  REBILLY_ERROR: 'rebilly-error',
  SERVER_ERROR: 'server-error',
  UNHANDLED_SERVER_RESPONSE: 'unhandled-server-response',
  THREEDS_DECLINED_ERROR: '3ds-declined-error',
  THREEDS_DECLINED_CUSTOM_ERROR: '3ds-declined-custom-error',
  PAYPAL_DECLINED_ERROR: 'paypal-declined-error',
  PAYPAL_CUSTOM_ERROR: 'paypal-custom-error',
}

const states = {
  StoreStates,
  SummaryStates,
  PaymentStates,
  SubmittingStates,
  PaypalStates,
  CouponStates,
  ErrorTypes,
  CheckoutStates,
}
const globals = getSSRGlobalResourceData()
const ssrDynamicData = globalThis.getSSRDynamicStoreData(globals.products)

globalThis.Checkout = {
  ...states,
  ...globals,
  ssrDynamicData,
  utils: buildUtils(),
}

window.addEventListener(
  'keydown',
  function (e: KeyboardEvent) {
    if (e.key == 'Enter') {
      if (globalThis.Checkout.store?.payment.type.get() == 'apple-pay') {
        e.preventDefault()
        return false
      }
    }
  },
  true
)

export type CheckoutFormErrors = {
  globalErrors?: Array<{ message: string }>
  fields?: Record<string, { message: string }>
}

export const cleanupEmptyErrors = (errors: CheckoutFormErrors): CheckoutFormErrors => {
  if (errors.globalErrors.length == 0) delete errors.globalErrors
  if (Object.keys(errors.fields).length == 0) delete errors.fields

  if (Object.keys(errors).length == 0) return null

  return errors
}
export function hasErrors(errors: CheckoutFormErrors): boolean {
  return (errors?.globalErrors ?? []).length != 0 || Object.keys(errors?.fields ?? {}).length != 0
}

export function productErrors(cart: CheckoutCart, showAllErrors: boolean): CheckoutFormErrors {
  const errors = {
    globalErrors: [],
    fields: {},
  }
  if (showAllErrors) {
    const hasProducts = cart.items
      .filter((p) => !globalThis.Checkout.productsById[p.product_id].bump)
      .some((item) => item.quantity > 0)

    if (!hasProducts) {
      errors.globalErrors.push({ message: 'At least one product should be selected' })
    }
  }
  return cleanupEmptyErrors(errors)
}

export function shippingOptionErrors(
  loadingShipping: boolean,
  cart: CheckoutCart,
  shippingOptions: ShippingOption[],
  shippingOption: ShippingOption,
  showAllErrors: boolean,
  paymentType: string
): CheckoutFormErrors {
  const errors = {
    globalErrors: [],
    fields: {} as any,
  }
  if (!showAllErrors || loadingShipping || !hasPhysicalProductsWithParams(cart) || paymentType == 'apple-pay') {
    return null
  }
  const shippingOptionIsValid = shippingOption && shippingOptions?.length > 0
  if (!shippingOptionIsValid) {
    const error = { message: 'Shipping is not available for this location' }
    errors.fields.shippingOption = error
    errors.globalErrors.push(error)
  }
  return cleanupEmptyErrors(errors)
}

export function tosErrors(accepted: boolean, present: boolean, showTosErrors: boolean): CheckoutFormErrors {
  if (present && showTosErrors && !accepted) {
    return { globalErrors: [{ message: 'You must accept the terms of service' }] }
  } else {
    return {}
  }
}

export function addressErrors(address: Address, showAllErrors: boolean, fields: string[]): CheckoutFormErrors {
  const errors = {
    globalErrors: [],
    fields: {},
  }

  fields.forEach((field) => {
    const value = address[field]
    if (value == undefined && !showAllErrors) return

    const { valid, message } = validateValue(field, value)
    if (!valid) {
      errors.fields[field] = { message }
    }
  })
  return cleanupEmptyErrors(errors)
}

export function contactErrors(
  contact: Record<string, string>,
  fields: string[],
  showAllErrors?: boolean
): CheckoutFormErrors {
  showAllErrors = showAllErrors ?? true
  const errors = {
    globalErrors: [],
    fields: {},
  }

  fields.forEach((field) => {
    const value = contact[field]
    if (value == undefined && !showAllErrors) return

    const { valid, message } = validateValue(field, value)
    if (!valid) {
      errors.fields[field] = { message }
    }
  })
  return cleanupEmptyErrors(errors)
}

// TODO(henrique): <ONE_STORE_PER_CHECKOUT> we dont wanna use globalThis.Checkout.store in this method.
// Instead we wanna store be passed as a parameter to the function utility
function buildUtils() {
  const billingErrors = (
    billing,
    billingSameAsShipping,
    showAllErrors,
    cart,
    mode,
    billingFields,
    billingApiErrorsByField,
    paymentType,
    paymentId
  ) => {
    if (
      (hasPhysicalProductsWithParams(cart) && billingSameAsShipping) ||
      mode == CheckoutStates.UPGRADE_DOWNGRADE ||
      (mode == CheckoutStates.OTO && !paymentId) ||
      paymentType == 'apple-pay'
    ) {
      return null
    } else if (billingApiErrorsByField) {
      return {
        fields: billingApiErrorsByField,
      }
    } else if (
      mode == globalThis.Checkout.CheckoutStates.REACTIVATE ||
      mode == globalThis.Checkout.CheckoutStates.SAVED
    ) {
      const savedBillingAddresses = globalThis.Checkout.store.billing_addresses.get()
      const isActiveAddressSaved = savedBillingAddresses.find((addr) => addr.id == billing.id)
      if (!isActiveAddressSaved) {
        return addressErrors(billing, showAllErrors, billingFields)
      }
      return null
    } else if (mode == CheckoutStates.OTO && paymentId) {
      return null
    } else {
      return addressErrors(billing, showAllErrors, billingFields)
    }
  }

  const shippingErrors = (shipping, showAllErrors, cart, mode, shippingFields, paymentType) => {
    if (
      !hasPhysicalProductsWithParams(cart) ||
      mode == globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE ||
      paymentType == 'apple-pay'
    ) {
      return null
    } else {
      return addressErrors(shipping, showAllErrors, shippingFields)
    }
  }

  return {
    productErrors,
    billingErrors,
    shippingErrors,
    addressErrors,
    cleanupEmptyErrors,
    hasErrors,
    hasPhysicalProductsWithParams,
    skipBillingAddress: (store) => {
      const isNotDigitalWalletPayment = store.payment.type.get() != 'apple-pay'
      return isNotDigitalWalletPayment && store.billingFields.get().length == 0
    },
    hasPhysicalProducts: (store) => {
      const cart = globalThis.Checkout.computed.checkoutCart.get()
      return hasPhysicalProductsWithParams(cart)
    },
    hasValidDataForOrderPreview: (options) => {
      const mode = globalThis.Checkout.store.checkout.mode.get()

      const cart = globalThis.Checkout.computed.checkoutCart.get()
      const billing = globalThis.Checkout.store.billing.get()
      const shipping = globalThis.Checkout.store.shipping.get()
      const billingSameAsShipping = globalThis.Checkout.store.billingSameAsShipping.get()
      const billingApiErrorsByField = globalThis.Checkout.store.billingApiErrorsByField.get()

      const paymentType = globalThis.Checkout.store.payment.type.get()
      const paymentId = globalThis.Checkout.store.payment.id.get()

      const showAllBillingErrors = true
      const showAllShippingErrors = true
      const showAllProductErrors = true

      const pErrors = productErrors(cart, showAllProductErrors)
      const bErrors = billingErrors(
        billing,
        billingSameAsShipping,
        showAllBillingErrors,
        cart,
        mode,
        options.billingFields,
        billingApiErrorsByField,
        paymentType,
        paymentId
      )
      const sErrors = shippingErrors(shipping, showAllShippingErrors, cart, mode, options.shippingFields, paymentType)
      return !hasErrors(pErrors) && !hasErrors(bErrors) && !hasErrors(sErrors)
    },
    hasValidDataForShippingOptions: (options) => {
      const mode = globalThis.Checkout.store.checkout.mode.get()

      const cart = globalThis.Checkout.computed.checkoutCart.get()
      const billing = globalThis.Checkout.store.billing.get()
      const shipping = globalThis.Checkout.store.shipping.get()
      const paymentType = globalThis.Checkout.store.payment.type.get()

      const showAllShippingErrors = true

      const sErrors = shippingErrors(shipping, showAllShippingErrors, cart, mode, options.shippingFields, paymentType)
      return !hasErrors(sErrors)
    },
    hasValidDataForTaxes: (options) => {
      const mode = globalThis.Checkout.store.checkout.mode.get()

      const cart = globalThis.Checkout.computed.checkoutCart.get()
      const billing = globalThis.Checkout.store.billing.get()
      const shipping = globalThis.Checkout.store.shipping.get()
      const billingSameAsShipping = globalThis.Checkout.store.billingSameAsShipping.get()
      const billingApiErrorsByField = globalThis.Checkout.store.billingApiErrorsByField.get()
      const paymentType = globalThis.Checkout.store.payment.type.get()
      const paymentId = globalThis.Checkout.store.payment.id.get()

      const showAllBillingErrors = true

      const bErrors = billingErrors(
        billing,
        billingSameAsShipping,
        showAllBillingErrors,
        cart,
        mode,
        options.billingFields,
        billingApiErrorsByField,
        paymentType,
        paymentId
      )
      return !hasErrors(bErrors)
    },
    canSubmit: () => {
      const state = globalThis.Checkout.store.state.get()
      const summary = globalThis.Checkout.store.summary.get()
      return (
        state == globalThis.Checkout.StoreStates.FILLING_FORM && summary.state == globalThis.Checkout.SummaryStates.OK
      )
    },
  }
}
